<template>
    <div class="page">
        <div class="content">
            <div style="width: 100%;height: 80vh;background-color: #fff;padding: 20px;">
                <Form class="form" ref="formItem" :model="formItem" :label-width="100">
                    <FormItem label="页面标题" prop="title">
                        <Input class="input" v-model="formItem.title" placeholder="请输入页面标题"></Input>
                    </FormItem>
                    <FormItem label="页面内容" prop="content">
                        <quill-editor
                            v-model="formItem.content"
                            ref="myQuillEditor"
                            :options="editorOption"
                            @blur="onEditorBlur($event)"
                            @focus="onEditorFocus($event)"
                            @change="onEditorChange($event)"
                            @ready="onEditorReady($event)">
                        </quill-editor>
                    </FormItem>
                    <FormItem class="foot">
                        <Button v-if="$route.path == '/systemsetup/pagecontent/editpage'" style="margin-right: 20px;" @click="goBack">返回</Button>
                        <Button type="primary" @click="handleSubmit">保存</Button>
                    </FormItem>
                </Form></div>
        </div>
    </div>
</template>

<script>
import { quillEditor } from 'vue-quill-editor'
import { pageCreate,pageInfos} from "@/api/index";
    export default {
        components: {quillEditor},
        data() {
            return {
                content:'',
                formItem: {
                    id:'',
                    title: '',
                    content:''
                },
                // 富文本编辑器配置
                editorOption: {
                    modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                        ['blockquote', 'code-block'], // 引用  代码块
                        [{ header: 1 }, { header: 2 }], // 1、2 级标题
                        [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                        [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                        [{ indent: '-1' }, { indent: '+1' }], // 缩进
                        [{ direction: 'rtl' }], // 文本方向
                        [{ size: ['12', '14', '16', '18', '20', '22', '24', '28', '32', '36'] }], // 字体大小
                        [{ header: [1, 2, 3, 4, 5, 6] }], // 标题
                        [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                        // [{ font: ['songti'] }], // 字体种类
                        [{ align: [] }], // 对齐方式
                        ['clean'], // 清除文本格式
                        ['image', 'video'] // 链接、图片、视频
                    ]
                    },
                    placeholder: '请输入正文'
                },
            }
        },
        created(e){            
            let id = this.$route.query.id ? this.$route.query.id : ''
            if(id){
                //修改
                this.formItem.id = id
                this.pageInfos()
            }
            else {
                //新增
                this.formItem.id = ''
                this.formItem.title = ''
                this.formItem.content = ''
            }            
            console.log('editpage', this.formItem.id )           
        },
        methods: {
            pageInfos(){
                pageInfos({id:this.formItem.id}).then(res=>{
                    // this.formItem.id = res.data.id
                    this.formItem.title = res.data.title
                    this.formItem.content = res.data.content
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
            },
            handleSubmit () {
                if(!this.formItem.title){
                    this.$Message.error('请输入页面标题');
                    return
                }
                pageCreate(this.formItem).then(res=>{
                    this.$Message.success(res.msg)
                }).catch(err=>{
                    this.$Message.error(err.msg)
                })
                this.$router.push({path: '/systemsetup/pagecontent'})
            },
            goBack(){
                this.$router.push({path: '/systemsetup/pagecontent'})
            },
            // 失去焦点事件
            onEditorBlur(quill) {
                // console.log('editor blur!', quill)
            },
            // 获得焦点事件
            onEditorFocus(quill) {
                // console.log('editor focus!', quill)
            },
            // 准备富文本编辑器
            onEditorReady(quill) {
                // console.log('editor ready!', quill)
            },
            // 内容改变事件
            onEditorChange({ quill, html, text }) {
                console.log('editor change!', quill, html, text)
                this.content = html
            },
        },
    }
</script>

<style lang="scss" scoped>
.page{
    background-color: #F5F9FA;
    height: 100%;
}
.head{
    width: 100%;
    height: 70px;
    background-color: #fff;
    margin-bottom: 10px;
    div{
        float: left;
        margin: 10px 0 0 10px;
    }
}
.content{
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    position: relative;
    .form{
        margin-top: 20px;
        .input{
            width: 200px;
        }
        .foot{
            position: absolute;
            width: 95%;
            bottom: 0;
            padding: 10px 0;
            border-top: 1px solid #ccc;
            margin: 10px 0;
            text-align: right;
        }
    }
}
::v-deep .ql-editor{
    height: 450px;
}
</style>